@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&family=Rubik+Doodle+Shadow&display=swap');

.p-flamingCore {
  font-family: 'Cabin Sketch', sans-serif;
  line-height: 160%;
  letter-spacing: 0.02em;
  font-size: 17px;
  font-weight: 400;
  margin: 0.8em 0;
}

.background-img-flamingCore {
  background-image: url(img/Towers-Shooting.webp);
  background-position: center;
  background-size: cover;
  -webkit-mask: url(img/brushes2.webp);
  mask: url(img/brushes2.webp);
  mask-position: center;
  mask-size: cover;
  mask-repeat: no-repeat;
}

.flamingCore-section__title {
  font-family: 'Cabin Sketch', sans-serif;
  font-size: 50px;
  padding-bottom: 0.1em;
  font-weight: 800;
  padding: 7px;
  text-align: left;
  position: relative;
}

.background-flamingCore {
  position: absolute;
  inset: 0;
  background-image: url(img/FlaminCoreDefence.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mask-flamingcore {
  mask: url(img/brushes1.webp);
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
}

.text-container {
    width: 450px;
}
/*-------------------------------------------------------------------------------------*/
@media screen and (min-width:360px) and (max-width: 667px) {
  .flamingCore-section__title {
    font-size: 40px;
  }
  
  .text-container {
    width: 100%;
}
  .game-section__subtitle {
    font-size: 18px;
  }
  .p-flamingCore {
    font-size: 16px;
  }
}
@media screen and (min-width:667px) and (max-width: 805px){
    .p-flamingCore {
        font-size: 16px;
      }
}

