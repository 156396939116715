* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a {
  background-color: transparent;
}

img {
  border-style: none;
}

/*---------------------HEADER-------------------------------*/
.app-header {
  background-color: #0a0b1400;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header__logo {
  position: relative;
  height: 100px;
}

.app-header__logo-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.app-header__links {
  list-style: none;
  width: 100%;
}

.app-header__links-list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  height: 100%;
}

.app-header__link-list-item {
  color: #fff;
  height: 100%;
  margin: 0 15px;
}

.app-header__link {
  font-size: 15px;
  letter-spacing: .05em;
  color: #cacaca;
  text-transform: uppercase;
  font-weight: bolder;
  position: relative;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .3s ease;
}

@media screen and (min-width: 375px) and (max-width: 667px) {

  .app-header__link {
    font-size: 13px;
  }

}