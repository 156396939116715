* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #000000;
}

.home-containers {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    height: 550px;
}

.gameCardsContainer {
    margin-top: 25px;
    margin-bottom: 25px;

}