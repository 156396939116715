.home-img {
  height: -webkit-fill-available;
  background-position: center;
  background-size: cover;
  background-image: url(img/17031.webp);
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

}
.mask-home{
  -webkit-mask: url(img/brushes2.webp);
  mask: url(img/brushes2.webp);
  mask-size: contain;
  mask-position: center; 
  
}
.home-logo {
  width: 400px;
  height: 400px;
}
/*Smartphone*/
@media screen and (max-width: 767px) {
  .home-logo {
    width: 357px;
    height: 400px;
  }
  .mask-home{
    -webkit-mask: url(img/brushes2.webp);
    mask: url(img/brushes2.webp);
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
  }
  
}
/*Tablet*/
@media screen and (min-width: 768px) and (max-width: 1038px ) {
  .home-logo {
    width: 357px;
    height: 400px;
  }
  .mask-home{
    -webkit-mask: url(img/brushes2.webp);
    mask: url(img/brushes2.webp);
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
  }
  
}