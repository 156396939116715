@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch&family=Rubik+Doodle+Shadow&display=swap');
/*
tipografia
font-family: 'Cabin Sketch', sans-serif;
font-family: 'Rubik Doodle Shadow', system-ui;

titulos
font-size: 60px;

subtitulos
font-size: 25px;

*/

p {
    font-family: 'Cabin Sketch', sans-serif;
    line-height: 160%;
    letter-spacing: 0.02em;
    font-size: 20px;
    font-weight: 400;
    margin: 0.8em 0;
}

.background-img-adhd {
    background-image: url(img/ARomanColosse.webp);
    background-position: center;
    background-size: cover;
}
.mask-adhd {
    mask: url(img/brushes1.webp);
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
}

.container-gameCard {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.box-content {
    height: 400px;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0px 0px 20px 11px #434343b8;
    color: white;
    padding: 20px;
    background: rgb(255 255 255 / 17%);
    border-radius: 16px 0 0 16px;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
}
.text-container {
    width: 450px;
}

.game-section__title {
    font-family: 'Cabin Sketch', sans-serif;
    color: #860000;
    font-size: 60px;
    padding-bottom: 0.1em;
    font-weight: 800;
    text-align: left;
    position: relative;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.game-section__subtitle {
    font-family: 'Cabin Sketch', sans-serif;
    font-size: 25px;
    line-height: 160%;
    letter-spacing: 0.02em;
    font-weight: 400;
}


.card {
    position: relative;
    width: 450px;
    height: 400px;
    background: #f7f7f7;
    border-radius: 0px 30px 30px 0px;
    overflow: hidden;
    transition: all 1s ease-in-out;
    box-shadow: 20px 0px 20px 7px #434343b8;
}

.background-adhd {
    position: absolute;
    inset: 0;
    background-image: url(img/adhdArena2.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.logo-steam {
    display: inline-block;
    width: 360px;
    height: 100px;
    z-index: 2;
}

.logo-steam .svg {
    fill: rgba(255, 255, 255, 0.797);
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.box {
    display: flex;
    flex-direction: row;
    position: absolute;
    padding: 10px;
    text-align: right;
    background: rgba(255, 255, 255, 0.389);
    border-radius: 0 25px 15px 0;
    box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
    transform-origin: bottom left;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    justify-content: center;
    align-items: center
}

.box::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    transition: all 0.1s ease-in-out;
}

.box:hover .svg {
    fill: white;
}

.box1 {
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: -70%;
}

.box1::before {
    background: rgb(17, 147, 226);
    background: -moz-linear-gradient(0deg,
            rgba(17, 147, 226, 1) 0%,
            rgba(0, 17, 83, 1) 60%);
    background: -webkit-linear-gradient(0deg,
            rgba(17, 147, 226, 1) 0%,
            rgba(0, 17, 83, 1) 60%);
    background: linear-gradient(0deg,
            rgba(17, 147, 226, 1) 0%,
            rgba(0, 17, 83, 1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1193e2", endColorstr="#001153", GradientType=1);
}

.box1:hover::before {
    opacity: 1;
}

.box1:hover .icon-steam .svg {
    filter: drop-shadow(0 0 5px white);
}

.card:hover .box {
    bottom: 0px;
    left: 0;
}

.card:hover .logo {
    transform: translate(80px, -50px);
    letter-spacing: 0px;
    width: max-content;
}

/*-------------------------------------------------------------------------------------*/
@media screen and (min-width: 360px) and (max-width: 667px) {
    .gameCardContainer {
        margin-bottom: 0px;
    }

    .text-container {
        width: 100%;
    }

    .container-gameCard {
        flex-direction: column;
    }

    .game-section__title {
        font-size: 3rem;
    }

    .box-content {
        height: 380px;
        border-radius: 16px 16px 0 0;

    }

    .container-gameCard {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 60px;
        padding-bottom: 100px;
    }

    .card {
        width: 100%;
        height: 330px;
        border-radius: 0px 0px 30px 30px;

    }

    .steam {
        font-size: 60px;
    }

    .box {
        border-radius: 0 0 25px 15px;
    }
}

@media screen and (min-width:667px) and (max-width: 805px){
    p {
        font-size: 16px;
      }
}
