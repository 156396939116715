.app-footer {
  height: 250px;
}
.app-footer {
  background: radial-gradient(circle, #19162b 0, #19162b 14%, #0c0818 50%);
  position: relative;
}
.app-footer__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-footer__logo {
  width: 200px;
}
.app-footer__link {
  color: #9896ae;
  margin-top: 10px;
  padding: 0 0.8em;
  font-weight: 200;
  text-transform: uppercase;
  font-size: 0.9em;
  border-right: 1px solid #9896ae;
}
.app-footer__links{
  display: flex;
}

.mask-footer {
  mask: url(img/brushes3.webp);
  mask-size: cover;
  mask-position: center;
  mask-repeat: no-repeat;
}